<template>
  <div id="engagement" class="form-horizontal">
    <div class="title-bar">
      <div class="pull-right center-float" v-if="!formActive"><Button id="engagementEditBtn" @click="editDetails" :label="$t(`edit_details`)"></Button></div>
      <div class="pull-right center-float" v-if="formActive"><Button id="engagementCancelBtn" color="secondary" @click="cancel" :label="$t(`cancel`)"></Button></div>
    </div>
    <Form v-if="!formActive" id="Engagement" key="Engagement" :name="'Engagement'" :isEditing="false" :loadFormData="true" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId" />
    <Form v-else id="editEngagement" key="EditEngagement" :name="'EditEngagement'" :isEditing="editEngagement" :loadFormData="editEngagement" :getFormUrlParams="'idName=PATIENT&id=' + patientId" :saveFormUrlParams="'?episodeId=' + patientId" />

  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState } from 'vuex'

export default {
  name: 'App',
  data: self => ({
    formActive: false,
    editEngagement: false
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.back)
  },
  destroyed () {
    EventBus.$off('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form, Button
  },
  computed: {
    ...mapState('Form', ['isLoading']),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    editDetails () {
      if (!this.isLoading) {
        this.editEngagement = true
        this.formActive = true
      }
    },
    cancel () {
      if (!this.isLoading) {
        this.editEngagement = false
        this.formActive = false
      }
    },
    back () {
      if (!this.isLoading) {
        this.editEngagement = false
        this.formActive = false
      }
    }
  }
}
</script>

<style>
.form-horizontal {
  background-color: var(--background-color-primary-light-dark);
  padding: 20px 0 10px 0;
}
.center-float {
  margin-right:20px;
}
.pull-right {
  justify-content: flex-end;
  display: flex;
}
.text-no-margin {
  margin: auto;
}
</style>
